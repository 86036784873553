
/** @jsx jsx */
import { Component } from "react"
import { jsx, css } from '@emotion/react'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { navigate } from '../../helpers/queryNavigate';


// FINANCIERA COMPONENTS
import { CardElevation, TitleIcon, ButtonDegrade } from '@findep/mf-landings-core'

//MATERIAL UI COMPONENTS
import { FormLabel, FormControlLabel, RadioGroup, Radio, FormControl, Grid, Container, Backdrop, Snackbar, Box } from "@material-ui/core"
import { Alert } from '@material-ui/lab';

//MODULES PROPS SYSTEM
import { MaterialInput } from '@findep/mf-landings-core';

//ICONS MATERIAL UI
import { HowToRegOutlined, ArrowForward } from '@material-ui/icons/';
import CircularProgress from '@material-ui/core/CircularProgress';

//SERVICIOS 
import { custionarioEbr } from '../../services/LandingV4/cuestionarioEbr'
import { Fragment } from "react";



const sizeGrl = css`  
   font-size: 16px;
    `
const cntTxtOne = css`  
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
        line-height: 1.5;
        letter-spacing: 0.15px;
    `
const bDrop = css`  
    z-index:9999;
    color: '#fff';
  `
export default class CuestionarioEbr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flujo: !((typeof window !== 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window !== 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')) : '' : '',
            habilita: true,
            bdHabilita: false,
            errorService: false,
            first: true,

            //variables para las acciones de los campos ocultar/mostrar/activar
            act_inpt_otro_recurso: false,
            act_person_pay: false,
            show_inpt_name_full: false,
            show_inpt_puesto_full: false,
            act_puesto: false,
            show_inpt_fam_pld_full: false,
            act_fam_pld: false,
            act_source_income: false,
            show_additional_income: false,
            act_additional_income: false,


            //variables de los nombres de los campos para enviarlos al bff
            recursos: '',
            otro_recurso: '',
            person_pay: '',
            full_name: '',
            puesto: '',
            puesto_desc: '',
            famPld: '',
            famPld_desc: '',
            source_income: '',
            additional_income: '',
            mount: '',


            _errors: {
                mount: {
                    error: false,
                    errorType: 'Se aceptan solo dígitos',
                },
                full_name: {
                    error: false,
                    errorType: 'Escribe tu nombre completo',
                },
                puesto: {
                    error: false,
                    errorType: 'Escribe tu puesto completo',
                },
                puesto_desc: {
                    error: false,
                    errorType: 'Escribe tu puesto completo',
                },
                famPld: {
                    error: false,
                    errorType: 'Escribe tu puesto completo',
                },
                famPld_desc: {
                    error: false,
                    errorType: 'Escribe tu puesto completo',
                },
                otro_recurso: {
                    error: false,
                    errorType: 'Especifica otro recurso',
                },
                recursos: {
                    error: false,
                    errorType: '',
                },
            }
        }
        this.handleChange = this.handleChange.bind(this)
        this._handleError = this._handleError.bind(this)
        this.createPDFDocument = this.createPDFDocument.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('entro', prevState.habilita,' state ',this.state.habilita);
        if (prevState.habilita !== this.state.habilita) {
            // console.log('pregunto');

            if (
                ((this.state.recursos === 'otro' && this.state.otro_recurso !== '' && !this.state._errors.otro_recurso.error) || this.state.recursos !== 'otro') &&
                ((this.state.person_pay === 'S' && this.state.full_name !== '' && !this.state._errors.full_name.error) || this.state.person_pay === 'N') &&
                ((this.state.source_income === 'S' && !this.state._errors.mount.error && (this.state.additional_income !== '' && this.state.mount !== '')) || this.state.source_income === 'N') &&
                ((this.state.puesto === 'S' && this.state.puesto_desc !== '') || this.state.puesto === 'N') &&
                ((this.state.famPld === 'S' && this.state.famPld_desc !== '') || this.state.famPld === 'N')
            ) {
                // console.log('entro al if');
                this.setState({ habilita: false })

            } else {
                // console.log('entro al else');
                this.setState({ habilita: true })
            }
        }
    }

    _handleError(prop, data, bool = true) {
        let { _errors } = this.state
        _errors[prop] = {
            ..._errors[prop],
            error: bool
        }
        this.setState({ _errors })

    }

    createPDFDocument(state) {
        return new Promise((resolve, reject) => {

            const docDefinition = {
                content: [
                    { text: 'Cuestionario EBR-Medio', fontSize: 18, bold: true, margin: [0, 0, 0, 10] },
                    { text: '1. ¿De dónde provienen los recursos con los que paga su crédito?', bold: true },
                    { text: `- Respuesta seleccionada: ${state.recursos}`, margin: [0, 5, 0, 10] },
                    state.act_inpt_otro_recurso && { text: `- Especificar otro: ${state.otro_recurso}`, margin: [0, 5, 0, 10] },
                    { text: '2. ¿Alguna persona le apoyará para realizar sus pagos?', bold: true },
                    { text: `- Respuesta seleccionada: ${state.person_pay === 'S' ? 'Sí' : 'No'}`, margin: [0, 5, 0, 10] },
                    state.show_inpt_name_full && { text: `- Nombre completo de quien apoyará: ${state.full_name}`, margin: [0, 5, 0, 10] },
                    { text: '3. ¿Cuenta con otra(s) fuente(s) de ingreso(s)?', bold: true },
                    { text: `- Respuesta seleccionada: ${state.source_income === 'S' ? 'Sí' : 'No'}`, margin: [0, 5, 0, 10] },
                    state.show_additional_income && { text: `- Frecuencia de monto ingresos Adicionales: ${state.additional_income}`, margin: [0, 5, 0, 10] },
                    state.show_additional_income && { text: `- Monto ingresos Adicionales: ${state.mount}`, margin: [0, 5, 0, 10] },
                    { text: '4. ¿Usted desempeña o ha desempeñado funciones públicas destacadas en un país extranjero o en territorio nacional?', bold: true },
                    { text: `- Respuesta seleccionada: ${state.puesto === 'S' ? 'Sí' : 'No'}`, margin: [0, 5, 0, 10] },
                    state.show_inpt_puesto_full && { text: `- Puesto desempeñado: ${state.puesto_desc}`, margin: [0, 5, 0, 10] },
                    { text: '5. ¿Usted es cónyuge, concubina o concubinario, padre, hijo o hermano de alguien que desempeñe o haya desempeñado funciones públicas destacadas en un país extranjero o en territorio nacional?', bold: true },
                    { text: `- Respuesta seleccionada: ${state.famPld === 'S' ? 'Sí' : 'No'}`, margin: [0, 5, 0, 10] },
                    state.show_inpt_fam_pld_full && { text: `- Especifique puesto: ${state.famPld_desc}`, margin: [0, 5, 0, 10] },
                ],
                defaultStyle: {
                    fontSize: 14
                }
            };
            // Elimina los elementos falsos (undefined, false) del array 'content'
            docDefinition.content = docDefinition.content.filter(Boolean);

            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            pdfDocGenerator.getBlob((blob) => {
                const file = new File([blob], "ebr-medio.pdf", {
                    type: "application/pdf",
                });
                resolve(file);
            }, reject);
        });
    }

    //GUARDA LOS ESTADOS DE LOS INPUTS
    handleChange(prop, data) {
        const state = {
            [prop]: data.value,
            habilita: false
        }
        this._handleError(prop, {}, false)

        const tmp = Object.assign({}, this.state, state)
        this.setState(tmp, () => {


            if (prop === "full_name") {
                this.setState({ act_source_income: !this.state._errors.otro_recurso.error, habilita: true })
            }

            if (prop === "otro_recurso") {
                this.setState({ act_person_pay: !this.state._errors.otro_recurso.error, habilita: true })
            }
            if (prop === "mount" || prop === 'famPld_desc' || prop === 'puesto_desc') {
                this.setState({ habilita: true })
            }

            if (prop === "recursos") {
                if (data.value === "otro") {
                    this.setState({ act_inpt_otro_recurso: true, act_person_pay: false, habilita: true })
                } else {
                    this.setState({ act_inpt_otro_recurso: false, act_person_pay: true, otro_recurso: '' })
                }
            }

            if (prop === "person_pay") {
                if (data.value === "S") {
                    this.setState({ show_inpt_name_full: true, act_source_income: false, habilita: true })
                } else {
                    this.setState({ show_inpt_name_full: false, act_source_income: true, full_name: '' })
                }
            }
            if (prop === "puesto") {
                if (data.value === "S") {
                    this.setState({ show_inpt_puesto_full: true, act_puesto: false, habilita: true })
                } else {
                    this.setState({ show_inpt_puesto_full: false, act_puesto: true, puesto_desc: '' })
                }
            }
            if (prop === "famPld") {
                if (data.value === "S") {
                    this.setState({ show_inpt_fam_pld_full: true, act_fam_pld: false, habilita: true })
                } else {
                    this.setState({ show_inpt_fam_pld_full: false, act_fam_pld: true, famPld_desc: '' })
                }
            }

            if (prop === "source_income") {
                if (data.value === "S") {
                    this.setState({ show_additional_income: true, act_additional_income: true, habilita: true })
                } else {
                    this.setState({ show_additional_income: false, act_additional_income: false, mount: '' })
                }
            }
        })


    }

    async stepSubmit() {
        const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        let id = flujo?.creditApplicationId
    
        this.setState({ habilita: true, bdHabilita: true });
    
        try {
            // Creación del documento PDF de manera asincrónica
            const doc = await this.createPDFDocument(this.state);
    
            // Instanciar el servicio con el id
            const catalogues = new custionarioEbr(id);
    
            // Preparar los datos para enviar
            catalogues.setData({
                resource: this.state.recursos,
                specificationResource: this.state.otro_recurso,
                personPay: this.state.person_pay,
                specificationPersonPay: this.state.full_name,
                otherIncome: this.state.source_income,
                frecuenciIncome: this.state.additional_income,
                mount: this.state.mount,
                personalPld: this.state.puesto,
                specificationPersonalPld: this.state.puesto_desc,
                familiarPld: this.state.famPld,
                specificationFamiliarPld: this.state.famPld_desc,
                doc
            });
        
            // Envío de los datos
            const response = await catalogues.send();
            navigate(this.props.pageContext.next.path);
        } catch (error) {
            this.setState({ errorService: true, habilita: false, bdHabilita: false });
        }
    }
    

    render() {
        return (
            <Container maxWidth="sm" justify="center" css={sizeGrl}>

                <Grid container justify="center">
                    <Grid item style={{ textAlign: "center" }}>
                        <TitleIcon icon={HowToRegOutlined} title="Cuestionario EBR" size={20} textColor />
                    </Grid>
                </Grid>
                <br />
                <CardElevation>
                    <Grid container spacing={2}  >
                        <Grid item xs={12} justify="left" >
                            <FormControl component="fieldset">
                                <FormLabel component="legend" >¿De dónde provienen los recursos con los que paga su crédito?</FormLabel>
                                <RadioGroup aria-label="recursos" name="recursos" value={this.value} onChange={(_event, component) => this.handleChange("recursos", { value: _event.target.value })} row style={{ marginLeft: '30px' }}>
                                    <FormControlLabel value="publico" control={<Radio id="cuestionario-ebr-publicos-radio" />} label="Públicos" />
                                    <FormControlLabel value="privado" control={<Radio id="cuestionario-ebr-privados-radio" />} label="Privados" />
                                    <FormControlLabel value="otro" control={<Radio id="cuestionario-ebr-publicos-otro" />} label="Otro" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {this.state.act_inpt_otro_recurso &&
                            <Fragment>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={11} md={10}>


                                    <MaterialInput
                                        type="text"
                                        id="cuestionario-ebr-otro-recurso"
                                        label="Especificar otro"
                                        value={this.state.otro_recurso}
                                        pattern="[A-ZÑÁÉÍÓÚÜ]{2,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                                        accept=" A-ZÑÁÉÍÓÚÜ"
                                        transform={(value) => value.toUpperCase().slice(0, 60)}
                                        error={this.state._errors.otro_recurso.error}
                                        onError={(error) => this._handleError('otro_recurso', error)}
                                        errorMessage={this.state._errors.otro_recurso.errorType}
                                        onChange={(_event, component) => this.handleChange('otro_recurso', component)}
                                        required
                                        helperText="Especifica otro recurso"
                                    />
                                </Grid>
                            </Fragment>
                        }

                    </Grid>

                </CardElevation>
                <br />
                <CardElevation>
                    <Grid container spacing={2}  >
                        <Grid item xs={12} justify="left" >
                            <FormControl component="fieldset">
                                <FormLabel component="legend" >¿Alguna persona le apoyará para realizar sus pagos?</FormLabel>
                                <RadioGroup aria-label="person_pay" name="person_pay" value={this.value} onChange={(_event, component) => this.handleChange("person_pay", { value: _event.target.value })} row style={{ marginLeft: '30px' }}>
                                    <FormControlLabel value="S" control={<Radio id="cuestionario-ebr-person_pay-radio" />} label="Si" disabled={!this.state.act_person_pay != ""} />
                                    <FormControlLabel value="N" control={<Radio id="cuestionario-ebr-person_pay-radio" />} label="No" disabled={!this.state.act_person_pay != ""} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {this.state.show_inpt_name_full &&
                            <Fragment>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={11} md={10} >
                                    <MaterialInput
                                        type="text"
                                        id="cuestionario-ebr-full-name"
                                        label="Nombre completo"
                                        value={this.state.full_name}
                                        pattern="[A-ZÑÁÉÍÓÚÜ]{2,}( [A-ZÑÁÉÍÓÚÜ]{2})?"
                                        accept=" A-ZÑÁÉÍÓÚÜ"
                                        transform={(value) => value.toUpperCase().slice(0, 60)}
                                        error={this.state._errors.full_name.error}
                                        onError={(error) => this._handleError('full_name', error)}
                                        errorMessage={this.state._errors.full_name.errorType}
                                        onChange={(_event, component) => this.handleChange('full_name', component)}
                                        required
                                        helperText="Ingresa el nombre completo (Nombre(s), apellido paterno, apellido materno)"
                                    />
                                </Grid>
                            </Fragment>
                        }

                    </Grid>
                </CardElevation>
                <br />
                <CardElevation>
                    <Grid container spacing={2}  >
                        <Grid item xs={12} justify="left" >
                            <FormControl component="fieldset">
                                <FormLabel component="legend" >¿Cuenta con otra(s) fuente(s) de ingreso(s)?</FormLabel>
                                <RadioGroup aria-label="source_income" name="source_income" value={this.value} onChange={(_event, component) => this.handleChange("source_income", { value: _event.target.value })} row style={{ marginLeft: '30px' }}>
                                    <FormControlLabel value="S" control={<Radio id="cuestionario-ebr-fuente-ingresos-radio-si" />} label="Si" disabled={!this.state.act_source_income} />
                                    <FormControlLabel value="N" control={<Radio id="cuestionario-ebr-fuente-ingresos-radio-no" />} label="No" disabled={!this.state.act_source_income} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {this.state.show_additional_income &&
                            <Fragment>
                                <Grid item xs={12} justify="left" >
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" >Favor de indicar la frecuencia y el monto de su(s) ingreso(s) adicional(es):</FormLabel>
                                        <RadioGroup aria-label="additional_income" name="additional_income" value={this.value} onChange={(_event, component) => this.handleChange("additional_income", { value: _event.target.value })} row style={{ marginLeft: '30px' }}>
                                            <FormControlLabel value="semanal" control={<Radio id="cuestionario-ebr-additional_income-radio-semanal" />} label="Semanal" disabled={!this.state.act_additional_income} />
                                            <FormControlLabel value="quincenal" control={<Radio id="cuestionario-ebr-additional_income-radio-quincenal" />} label="Quincenal" disabled={!this.state.act_additional_income} />
                                            <FormControlLabel value="mensual" control={<Radio id="cuestionario-ebr-additional_income-radio-mensual" />} label="Mensual" disabled={!this.state.act_additional_income} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={11} md={10} >
                                    <MaterialInput
                                        id="cuestionario-ebr-mount"
                                        type="text"
                                        inputmode="numeric"
                                        autocomplete="postal-code"
                                        label="Indicar monto"
                                        value={this.state.mount}
                                        pattern="[0-9]{2}"
                                        accept="0-9"
                                        transform={(value) => value.slice(0, 7)}
                                        error={this.state._errors.mount.error}
                                        onError={(error) => this._handleError('mount', error)}
                                        onChange={(_event, component) => this.handleChange("mount", component)}
                                        required
                                        errorMessage="Se aceptan solo dígitos"
                                        disabled={!this.state.additional_income}
                                    />
                                </Grid>
                            </Fragment>
                        }



                    </Grid>
                </CardElevation>
                <br />
                <CardElevation>
                    <Grid container spacing={2}  >
                        <Grid item xs={12} justify="left" >
                            <FormControl component="fieldset">
                                <FormLabel component="legend" > ¿Usted desempeña o ha desempeñado funciones públicas destacadas en un país extranjero o en territorio nacional?</FormLabel>
                                <RadioGroup aria-label="person_puesto" name="person_puesto" value={this.value} onChange={(_event, component) => this.handleChange("puesto", { value: _event.target.value })} row style={{ marginLeft: '30px' }}>
                                    <FormControlLabel value="S" control={<Radio id="cuestionario-ebr-person_puesto-radio" />} label="Si" disabled={this.state.act_additional_income ? (this.state.additional_income !== '' && this.state.mount !== '') ? false : true : this.state.source_income !== '' ? false : true} />
                                    <FormControlLabel value="N" control={<Radio id="cuestionario-ebr-person_puesto-radio" />} label="No" disabled={this.state.act_additional_income ? (this.state.additional_income !== '' && this.state.mount !== '') ? false : true : this.state.source_income !== '' ? false : true} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {this.state.show_inpt_puesto_full &&
                            <Fragment>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={11} md={10} >
                                    <MaterialInput
                                        type="text"
                                        id="cuestionario-ebr-full-puesto"
                                        label="Especifique puesto:"
                                        value={this.state.puesto_desc}
                                        pattern="[A-ZÑÁÉÍÓÚÜ]{2,}( [A-ZÑÁÉÍÓÚÜ]{2})?"
                                        accept=" A-ZÑÁÉÍÓÚÜ"
                                        transform={(value) => value.toUpperCase().slice(0, 60)}
                                        error={this.state._errors.puesto_desc.error}
                                        onError={(error) => this._handleError('puesto', error)}
                                        errorMessage={this.state._errors.puesto.errorType}
                                        onChange={(_event, component) => this.handleChange('puesto_desc', component)}
                                        required
                                        helperText="Ingresa tu puesto completo"
                                    />
                                </Grid>
                            </Fragment>
                        }

                    </Grid>
                </CardElevation>
                <br />
                <CardElevation>
                    <Grid container spacing={2}  >
                        <Grid item xs={12} justify="left" >
                            <FormControl component="fieldset">
                                <FormLabel component="legend" >¿Usted es cónyuge, concubina o concubinario, padre, hijo o hermano de alguien que desempeñe o haya desempeñado funciones públicas destacadas en un país extranjero o en territorio nacional?</FormLabel>
                                <RadioGroup aria-label="fam_pld" name="fam_pld" value={this.value} onChange={(_event, component) => this.handleChange("famPld", { value: _event.target.value })} row style={{ marginLeft: '30px' }}>
                                    <FormControlLabel value="S" control={<Radio id="cuestionario-ebr-person_puesto-radio" />} label="Si" disabled={!this.state.act_puesto ? (this.state.puesto_desc !== '') ? false : true : this.state.puesto !== '' ? false : true} />
                                    <FormControlLabel value="N" control={<Radio id="cuestionario-ebr-person_puesto-radio" />} label="No" disabled={!this.state.act_puesto ? (this.state.puesto_desc !== '') ? false : true : this.state.puesto !== '' ? false : true} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {this.state.show_inpt_fam_pld_full &&
                            <Fragment>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={11} md={10} >
                                    <MaterialInput
                                        type="text"
                                        id="cuestionario-ebr-full-fam-pld"
                                        label="Especifique puesto:"
                                        value={this.state.famPld_desc}
                                        pattern="[A-ZÑÁÉÍÓÚÜ]{2,}( [A-ZÑÁÉÍÓÚÜ]{2})?"
                                        accept=" A-ZÑÁÉÍÓÚÜ"
                                        transform={(value) => value.toUpperCase().slice(0, 60)}
                                        error={this.state._errors.famPld_desc.error}
                                        onError={(error) => this._handleError('famPld', error)}
                                        errorMessage={this.state._errors.famPld.errorType}
                                        onChange={(_event, component) => this.handleChange('famPld_desc', component)}
                                        required
                                        helperText="Ingresa tu puesto completo"
                                    />
                                </Grid>
                            </Fragment>
                        }

                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="center">
                        <Grid item style={{ textAlign: "center" }}>
                            <ButtonDegrade
                                id="video-verificacion-jumio-button"
                                textButton='ENVIAR DATOS Y CONTINUAR'
                                onClick={() => this.stepSubmit()}
                                disabled={this.state.habilita}
                                icon={ArrowForward} />
                        </Grid>
                    </Grid>
                </CardElevation>
                <br />
                <Backdrop css={bDrop} open={this.state.bdHabilita} >
                    <CircularProgress color="primary" />
                </Backdrop>
                <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
                    <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
                        Lo sentimos, por el momento el servicio no se encuentra disponible
                    </Alert>
                </Snackbar>

                &nbsp;

            </Container>


        )
    }
}
